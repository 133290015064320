import React from "react"
import Map from "../components/MapContainer"

import styled from "@emotion/styled"

const apiKey = "AIzaSyC-hILdtig6zNpxg5pZNShnh6wGOVmJQho"

const IndexPage = props => {
  if (module.hot) {
    module.hot.addStatusHandler(status => {
      if (status === "apply") window.location.reload()
    })
  }

  const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
  `
  return (
    <Wrapper>
      <Map apiKey={apiKey} libraries={["places"]} />
    </Wrapper>
  )
}

export default IndexPage
