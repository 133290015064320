import React, { useState, useEffect } from "react"
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react"
import styled from "@emotion/styled"
import debounce from "debounce"
import Rating from "react-rating"
import "./layout.css"

const MapContainer = props => {
  const H1Google = styled.h1`
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 5px;
    padding: 0px;
    // line-height: 0px;
  `

  const Divider = styled.div`
    background-color: rgb(230, 230, 230);
    width: 100%;
    margin-top: 7px;
    margin-bottom: 7px;
    height: 1px;
  `

  const SubGoogleText = styled.span`
    font-family: Roboto, Arial, sans-serif;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    color: rgba(0, 0, 0, 0.87);
  `
  const URLLink = styled.a`
    text-decoration: none;
    color: inherit;
  `
  const IconImage = styled.img`
    display: block;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 5px;
  `

  const PhotoImage = styled.img`
    width: 100%;
    margin-bottom: 5px;
  `
  const [mapVisible, setMapVisible] = useState(false)

  const [position, setPosition] = useState({
    lat: 0,
    lng: 0,
  })
  const [map, setMap] = useState(null)
  const [google, setGoogle] = useState(null)

  useEffect(() => {
    if (google === null || map === null) return
    const service = new google.maps.places.PlacesService(map)
    const location = new google.maps.LatLng(position.lat, position.lng)
    // const bounds = map.getBounds()
    const request = {
      keyword: "walk-in clinic",
      openNow: false,
      // bounds,
      radius: 1000,
      location,
    }
    service.nearbySearch(request, data => {
      const markers = []
      data.forEach(p => {
        markers.push({
          position: {
            lat: p.geometry.location.lat(),
            lng: p.geometry.location.lng(),
          },
          placeId: p.place_id,
          key: p.place_id,
        })
      })
      setPlaces(markers)
    })
  }, [position])

  const [places, setPlaces] = useState([])
  const [infoWindowState, setInfoWindowState] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  })

  const onMapReady = (mapProps, m) => {
    setMap(m)
    setGoogle(mapProps.google)
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const newCoords = {
        lat: coords.latitude,
        lng: coords.longitude,
      }
      setPosition(newCoords)
      setMapVisible(true)
      // fetchPlaces(mapProps, map, newCoords)
    })
  }

  const onMarkerClick = (props, marker, e) => {
    // console.log(e)
    const service = new google.maps.places.PlacesService(map)
    // console.log(props)
    const request = {
      fields: [
        "formatted_address",
        "formatted_phone_number",
        "geometry",
        "name",
        "opening_hours",
        "photos",
        "rating",
        "vicinity",
        "website",
      ],
      placeId: props.placeId,
      key: props.placeId,
    }
    service.getDetails(request, data => {
      setInfoWindowState({
        showingInfoWindow: true,
        activeMarker: marker,
        selectedPlace: {
          ...data,
          website_formated: data.website
            ? data.website
                .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                .split("/")[0]
            : "",
        },
      })
    })
  }

  const onMapClicked = e => {
    if (infoWindowState.showingInfoWindow) {
      setInfoWindowState({
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
      })
    }
  }

  const onCenterMoved = (mapProps, m) => {
    const newCoords = {
      lat: map.center.lat(),
      lng: map.center.lng(),
    }
    // console.log(newCoords)
    setPosition(newCoords)
  }

  const renderPlaces = () => {
    if (places.length <= 0) return
    const markers = []
    for (const [index, value] of places.entries()) {
      // console.log(value)
      markers.push(
        <Marker
          {...value}
          onClick={onMarkerClick}
          icon={{
            url:
              "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
          }}
        />
      )
    }
    return markers
  }

  const renderInfoWindow = () => {
    const {
      name,
      vicinity,
      website,
      website_formated,
      opening_hours,
      formatted_phone_number,
      rating,
    } = infoWindowState.selectedPlace
    const photo = infoWindowState.selectedPlace.photos
      ? infoWindowState.selectedPlace.photos[0].getUrl()
      : ""
    return (
      <InfoWindow
        marker={infoWindowState.activeMarker}
        visible={infoWindowState.showingInfoWindow}
      >
        <div style={{ maxWidth: 230 }}>
          <PhotoImage src={photo} />
          <H1Google>{name}</H1Google>
          <Rating
            start={0}
            stop={5}
            step={1}
            emptySymbol="fa fa-star-o fa-2x"
            fullSymbol="fa fa-star fa-2x"
            initialRating={rating}
            style={{ color: "rgb(251, 189, 4)" }}
          />
          <Divider></Divider>
          <SubGoogleText>
            <IconImage src="//www.gstatic.com/images/icons/material/system_gm/2x/place_gm_blue_24dp.png" />
            <span>{vicinity}</span>
          </SubGoogleText>

          <SubGoogleText>
            <IconImage src="//www.gstatic.com/images/icons/material/system_gm/2x/public_gm_blue_24dp.png" />
            <span>
              <URLLink href={website}>{website_formated}</URLLink>
            </span>
          </SubGoogleText>
          <SubGoogleText>
            <IconImage src="//www.gstatic.com/images/icons/material/system_gm/2x/phone_gm_blue_24dp.png" />
            <span>{formatted_phone_number}</span>
          </SubGoogleText>
        </div>
      </InfoWindow>
    )
  }

  return (
    <Map
      className={"google-map"}
      google={props.google}
      zoom={14}
      center={position}
      onReady={onMapReady}
      visible={mapVisible}
      onClick={onMapClicked}
      onDragend={onCenterMoved}
    >
      <Marker position={position} name={"Current location"} />
      {renderPlaces()}
      {renderInfoWindow()}
    </Map>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyC-hILdtig6zNpxg5pZNShnh6wGOVmJQho",
  libraries: ["places", "geometry"],
})(MapContainer)
